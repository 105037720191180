<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Customers from "@/services/Customers";



export default {
    components: { Layout, PageHeader },
    page: {
        title: "Collectibles",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
   
    data() {
        return {
            title: "Collectibles",
            items: [
                {
                    text: "Fan Experience",
                },
                {
                    text: "Collectibles",
                    active: true,
                },
            ],
            error: null,
            showModal: false,
            modalData: {},
            tableData: [],
            showErrorMessage: false,
            filterClicked: false,
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: ['name', 'slug','type'],
            sortBy: "timestamp",
            showTable: false,
            sortDesc: true,
            totalCollectibles: 0,
            totalClaimed: 0,      
            totalImagePercent: 0,      
            fields: [
                {
                    key: "name",
                    label: "Name",
                    sortable: false,
                },
                {
                    key: "state",
                    label: "State",
                    sortable: false,
                },
                {
                    key: "type",
                    label: "Type",
                    sortable: false,
                },
                {
                    key: "start_time",
                    label: "Start Time",
                    sortable: false,
                },
                {
                    key: "slug",
                    label: "Slug",
                    sortable: false,
                },
                {
                    key: "active",
                    label: "Active",
                    sortable: false,
                },
                {
                    key: "collectibles_cnt",
                    label: "Collectibles",
                    sortable: false,
                },
                {
                    key: "image_cnt",
                    label: "Image",
                    sortable: false,
                },
                {
                    key: "image_percent",
                    label: "Image Percent",
                    sortable: false,
                },
                {
                    key: "claimed",
                    label: "Claimed",
                    sortable: false,
                },
            ],
            isBusy: false,
        };
    },
    computed: {
        rows() {
            return this.tableData.length;
        },
        formattedTotalCollectibles() {
        return new Intl.NumberFormat('en-US').format(this.totalCollectibles);
    },
    },
    mounted() {
        this.totalRows = this.items.length;
        this.getNftPacks(); 
    },
    methods: {
        async getNftPacks() {
    try {
        this.toggleBusy();
        const response = await Customers.getNftPacks(); 
        console.log("API Response:", response.data);
       
        this.tableData = response.data.list; 
        this.totalRows = this.tableData.length; 

        this.totalCollectibles = response.data.total_collectibles || 0;  
        this.totalClaimed = response.data.total_claimed || 0;          
        this.totalImagePercent = response.data.total_image_percent || 0;          

        this.toggleBusy();
        this.toggleTableVisibility(); 
    } catch (error) {
        this.toggleBusy();
        console.error("Error fetching nft packs:", error);
        this.error = error.response?.data?.error || error.message;
        this.tableData = []; 
    }
},

        
        toggleTableVisibility() {
            this.showTable = true; // Show the table
        },
      
        toggleBusy() {
            this.isBusy = !this.isBusy;
        }
    },
    middleware: "authentication",
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row mb-4">
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body text-center">
                        <p class="card-text fs-4 fw-bold text-primary">{{ formattedTotalCollectibles}}</p>
                        <h3 class="card-title">Total Collectibles</h3>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body text-center">
                    <p class="card-text fs-4 fw-bold text-success">{{ totalImagePercent }}%</p>
                        <h3 class="card-title">Image Percent</h3>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body text-center">
                        <p class="card-text fs-4 fw-bold text-warning">{{ totalClaimed }}</p>
                        <h3 class="card-title">Total Claimed</h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body" v-if="showTable">
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm ms-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                           
                        </div>
                        <div class="table-responsive mb-0">
                            <b-table
                                :items="tableData"
                                :busy="isBusy"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                show-empty
                                empty-text="No Data Found"
                            >
                <template v-slot:cell(name)="data">
  <router-link 
    :to="{ path: `/games/pack_details/${data.item.id}` }" 
    class="text-decoration-none text-primary"
  >
    {{ data.item.name }}
  </router-link>
</template>
                            <template #cell(state)="data">
    <span 
        :class="{
            'text-success fw-bold': data.value === '1',
            'text-danger fw-bold': data.value === '0'
        }"
    >
        {{ data.value === '1' ? 'Claimable' : 'Not Available' }}
    </span>
</template>


<template #cell(active)="data">
    <span 
        :class="{
            'text-success': data.value === 1,
            'text-danger': data.value === 0,  
        }"
    >
        <i v-if="data.value === 1" class="uil uil-check fa-2x text-success"></i>
        <i v-else-if="data.value === 0" class="uil uil-times fa-2x text-danger"></i>
      
    </span>
</template>

                                <template #table-busy>
                                    <div class="text-center text-black my-2">
                                        <b-spinner class="align-middle text-primary"></b-spinner>
                                        <strong class="text-primary">Loading...</strong>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info">
                                    Showing {{ (currentPage - 1) * perPage + 1 }} to
                                    {{ (currentPage - 1) * perPage + rows }} of {{ rows }} entries
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-7">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="right"
                                    size="sm"
                                ></b-pagination>
                            </div>
                        </div>
                    </div>
                    <!-- <div v-else class="text-center mt-4">
                        <strong>Use the filter to display the data</strong>
                    </div> -->
                </div>
            </div>
        </div>
    </Layout>
</template>
